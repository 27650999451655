import React from 'react';
import { Link } from 'gatsby';

const Header = () => (
  <header
    style={{
      background: 'rgb(237, 238, 238)',
      position: 'sticky',
      top: 0,
      zIndex: 99,
      textTransform: 'uppercase',
      fontFamily: 'MrsEaves',
      boxShadow: '0 2px 8px rgba(100, 100, 100, 0.3)',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          margin: 0,
        }}
      >
        <Link
          to="/"
          style={{
            color: '#945d3c',
            fontSize: '16px',
            textDecoration: 'none',
          }}
        >2019 Newsletter</Link>
      </h1>
      <a
        style={{
          margin: 0,
          color: '#945d3c',
          fontSize: '16px',
          textDecoration: 'none',
        }}
        href="https://www.mountmary.com.au"
      >Log in &gt;</a>
    </div>
  </header>
);

export default Header;
