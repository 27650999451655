import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header';
import './Layout.css';

const Layout = ({ children, footer, bounded, topPadded }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <>
      <Helmet title={site.siteMetadata.title}>
        <link rel='stylesheet' type='text/css' href='https://cloud.typography.com/7585974/726268/css/fonts.css' />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div
        style={{
          margin: bounded ? '0 auto' : undefined,
          maxWidth: bounded ? 960 : undefined,
          paddingTop: topPadded ? '40px' : undefined,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            color: footer === 'light' ? 'white' : undefined,
            padding: '16px',
          }}>
          © Mount Mary {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.string,
  bounded: PropTypes.bool,
  topPadded: PropTypes.bool,
};

Layout.defaultProps = {
  footer: 'dark',
  bounded: true,
  topPadded: true,
};

export default Layout;
