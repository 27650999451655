import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './Pagination.module.css';

const Pagination = ({ forwardLink, backLink }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        {
          backLink && (
            <Link
              to={backLink}
              className={styles.link}
            >&lt; Previous</Link>
          )
        }
      </div>
      <div>
        {
          forwardLink && (
            <Link
              to={forwardLink}
              className={styles.link}
            >Next &gt;</Link>
          )
        }
      </div>
    </div>
  );
};

Pagination.propTypes = {
  forwardLink: PropTypes.string,
  backLink: PropTypes.string,
};

export default Pagination;
