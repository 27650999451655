import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';

import styles from './global.module.css';

const GlobalPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Our global footprint">
        <p>
          It’s an exciting time for Australian wine overseas as our global
          footprint continues to improve. 2018 reported a 10% increase in
          Australian wine exports to a total annual value of $2.82 billion. This
          figure translates to 21.7 million glasses of Aussie wine being consumed
          overseas every day! Like many other sectors, we’re experiencing the
          &quot;premiumisation&quot; trend in the wine industry as consumers choose to drink
          quality over quantity.
        </p>
        <p>
          These global trends along with increased global migration mean we are
          experiencing more and more requests for our wines in overseas markets.
          Often these requests are from you, our mailing list clients, when you
          have relocated overseas or indeed have friends and family based outside of
          Australia, keen to enjoy Mount Mary wines. For this reason we are committed
          to growing a presence of Mount Mary wines (albeit in small quantities!) in
          key markets where there is demand.
        </p>
        <p>
          Sam and Claire travelled to Europe and Asia in January this year to
          explore some of these opportunities. It was a whirlwind trip (covering
          Amsterdam, London, Stokholm, Bordeaux and Hong Kong) over 12 days with
          many interesting insights gained along the way. As we engaged in
          conversations along the way with importers to consumers, it was fantastic
          to experience the bonds that Mount Mary wines facilitate. Though cultural
          differences exist, our passion for fine wines, paired with exceptional
          food, in the company of family and friends certainly unites us.
        </p>
        <p style={{ fontStyle: 'italic' }}>
          <sup>*</sup>Statistics from Wine Australia Market Bulletin, Jan 2019
        </p>
        <div className={styles.imageRow}>
          <Img fluid={data.image1.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image2.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image3.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image4.childImageSharp.fluid} className={styles.image} />
        </div>
        <div className={styles.imageRow}>
          <Img fluid={data.image5.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image6.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image7.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image8.childImageSharp.fluid} className={styles.image} />
        </div>
      </Section>
      <Pagination
        forwardLink="/chateau-margaux"
        backLink="/sustainability"
      />
    </Layout>
  );
};

GlobalPage.propTypes = {
  data: PropTypes.shape({
    eagleImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "global1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "global2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "global3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "global4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "global5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "global6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "global7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image8: file(relativePath: { eq: "global8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default GlobalPage;
