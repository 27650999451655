import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.module.css';

const Section = ({ heading, children, padded, background }) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        padding: padded ? '0 16px' : undefined,
        backgroundColor: background,
      }}
    >
      {
        heading && (
          <h2 className={styles.heading}>{heading}</h2>
        )
      }

      {children}
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  padded: PropTypes.bool,
  background: PropTypes.string,
};

Section.defaultProps = {
  padded: true,
};

export default Section;
